import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const checkIn = async (values) => {
   const data = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/order/customer_checkin`,
      values
   );
   return data;
};

export function useCheckIn() {
   const { isLoading, mutate } = useMutation(checkIn);

   return { isLoading: isLoading, checkIn: mutate };
}
