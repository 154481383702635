import React, { useEffect } from 'react';
import {
   Button,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
   TextField,
} from '@mui/material';

import { useStateContext } from '../Contexts/ContextProvider';
import { useCanvas } from '../Contexts/CanvasContext';

const CK_Finish = () => {
   const { serviceNames, massagePressure } = useStateContext();
   const { canvasRef, loadCanvas } = useCanvas();
   useEffect(() => {
      loadCanvas();
   }, []);
   return (
      <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems='center'
            justifyContent='center'
            direction='column'
         >
            <Grid item xs={6}>
               <Typography variant='h6' align='center'>
                  Thank you for checking in!
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant='subtitle1' align='center'>
                  Your booking details are below.
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant='subtitle1' align='center'>
                  {serviceNames.map((name, index) => (
                     <div key={index}>{name}</div>
                  ))}
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant='subtitle1' align='center'>
                  {massagePressure}
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <canvas ref={canvasRef} />
            </Grid>
         </Grid>
      </React.Fragment>
   );
};

export default CK_Finish;
