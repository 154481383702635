import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { ContextProvider } from './Contexts/ContextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { CanvasProvider } from "./Contexts/CanvasContext";

const tagManagerArgs = {
   gtmId: 'GTM-T6JF23B'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
   <React.StrictMode>
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <ContextProvider>
            <QueryClientProvider client={queryClient}>
               <LocalizationProvider dateAdapter={AdapterMoment}>
               <BrowserRouter>
                  <CanvasProvider>
                     <App />
                  </CanvasProvider>
                  </BrowserRouter>
               </LocalizationProvider>
               <ReactQueryDevtools initialIsOpen={true} />
            </QueryClientProvider>
         </ContextProvider>
      </ThemeProvider>
   </React.StrictMode>
);
