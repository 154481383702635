import './App.css';
import BookAppointment from './components/BookAppointment';
import CheckIn from './components/CheckIn';
import { Routes, Route } from 'react-router-dom';
import Feedback from './Feedback';
import NotFound from './NotFound';
import Url from './components/Url';

function App() {
   return (
      <Routes>
         <Route path="*" element={<NotFound />} />
         <Route path='/' element={<Url />} />
         <Route path='/checkin' element={<CheckIn />} />
         <Route path="/feedback/:id" element={<Feedback />} />
      </Routes>
   );
}

export default App;
