import React, { useContext, useRef, useState } from "react";

const CanvasContext = React.createContext();

export const CanvasProvider = ({ children }) => {
  const [isDrawing, setIsDrawing] = useState(false)
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const prepareCanvas = () => {
    const canvas = canvasRef.current
    const w = 500;
    const h = 500;
    canvas.width = w * 2;
    canvas.height = h * 2;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;

    const context = canvas.getContext("2d")
    context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = "red";
    context.lineWidth = 5;
    contextRef.current = context;
    const image = new Image();
    // image.crossOrigin = 'anonymous'
    image.src = require('../human-body.png')
    image.onload = () => {
      context.drawImage(image, 0, 0, 500, 500);
    };
  };

  const loadCanvas = () => {
    const canvas = canvasRef.current
    const w = 500;
    const h = 500;
    canvas.width = w * 2;
    canvas.height = h * 2;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;

    const context = canvas.getContext("2d")
    context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = "red";
    context.lineWidth = 5;
    contextRef.current = context;
    const image = new Image();
    // image.crossOrigin = 'anonymous'
    image.src = localStorage.getItem('canvas')
    image.onload = () => {
      context.drawImage(image, 0, 0, 500, 500);
    };
  };

  const startDrawing = (e) => {
    e.preventDefault();
    const { offsetX, offsetY } = e.nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = (e) => {
    e.preventDefault();
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const draw = (e) => {
    e.preventDefault();
    if (!isDrawing) {
      return;
    }
    const { offsetX, offsetY } = e.nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d")
    context.fillStyle = "white"
    context.fillRect(0, 0, canvas.width, canvas.height)
  }

  const saveCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d")
    let canvasUrl = canvas.toDataURL('image/png');
    localStorage.setItem('canvas', canvasUrl);
    console.log(canvasUrl);
  }

  return (
    <CanvasContext.Provider
      value={{
        canvasRef,
        contextRef,
        prepareCanvas,
        startDrawing,
        finishDrawing,
        clearCanvas,
        saveCanvas,
        loadCanvas,
        draw,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => useContext(CanvasContext);