import React, { useEffect, useState } from 'react';
import { useStateContext } from '../Contexts/ContextProvider';
import { FormikProvider } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import { useAddUpdateCustomer } from '../hooks/useAddUpdateCustomer';

const CK_Contact = ({ prevStep, nextStep }) => {
   const {
      setFirstName,
      setLastName,
      setEmail,
      setPhone,
      firstName,
      lastName,
      email,
      phone,
   } = useStateContext();
   const { isLoading, addUpdateCustomer } = useAddUpdateCustomer();

   let values = {
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      email: email,
   };

   const [formValues, setFormValues] = useState(values);
   const [formErrors, setFormErrors] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);

   const validate = (values) => {
      const errors = {};
      const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      const regex_phone =
         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

      if (!values.phone) {
         errors.phone = 'Required';
      }
      if (!regex_phone.test(values.phone)) {
         errors.phone = 'Phone number is not valid';
      }

      if (!values.first_name) {
         errors.first_name = 'Required';
      }

      if (!values.last_name) {
         errors.last_name = 'Required';
      }

      return errors;
   };
   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
   };

   useEffect(() => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
         //save value
         setFirstName(formValues.first_name);
         setLastName(formValues.last_name || '');
         setEmail(formValues.email || '');
         setPhone(formValues.phone);

         //update contact info
         let values = {
            phone: phone,
            firstName: firstName,
            lastName: lastName,
            email: email,
            store_id: process.env.REACT_APP_STORE_ID,
         };
         addUpdateCustomer(values, {
            onSuccess: () => {
               console.log('success');
            },
            onError: (error) => {
               console.log('error', error);
            },
         });
         nextStep();
      }
   }, [formErrors, isSubmit]);

   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='center'
               direction='row'
            >
               <Grid item xs={6}>
                  <TextField
                     id='phone'
                     name='phone'
                     label='Mobile Phone'
                     fullWidth
                     value={formValues.phone}
                     onChange={handleChange}
                     helperText={formErrors.phone}
                     required
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id='email'
                     name='email'
                     label='Email'
                     fullWidth
                     value={formValues.email}
                     onChange={handleChange}
                     helperText={formErrors.email}
                  />
               </Grid>

               <Grid item xs={6}>
                  <TextField
                     id='first_name'
                     name='first_name'
                     label='First Name'
                     fullWidth
                     value={formValues.first_name}
                     onChange={handleChange}
                     required
                     helperText={formErrors.first_name}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id='last_name'
                     name='last_name'
                     label='Last Name'
                     fullWidth
                     value={formValues.last_name}
                     onChange={handleChange}
                     required
                     helperText={formErrors.last_name}
                  />
               </Grid>

               <Grid
                  container
                  spacing={10}
                  direction='row'
                  justifyContent='flex-end'
                  marginTop={1}
               >
                  <Grid item xs={1}>
                     <Button onClick={prevStep}>Back</Button>
                  </Grid>
                  <Grid item xs={4}>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
};

export default CK_Contact;
