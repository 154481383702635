import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const addUpdateCustomer = async (values) => {
   const data = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/customer/add_update`,
      values
   );
   return data;
};

export function useAddUpdateCustomer() {
   const { isLoading, mutate } = useMutation(addUpdateCustomer);
   return { isLoading: isLoading, addUpdateCustomer: mutate };
}
