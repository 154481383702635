import React, { useState, useEffect,useRef } from 'react';
import { useStateContext } from '../Contexts/ContextProvider';
import {
   Button,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
   TextField,
} from '@mui/material';
import axios from 'axios';


const CK_Phone = ({ nextStep }) => {
   const {
      setPhone,
      phone,
      setFirstName,
      firstName,
      setLastName,
      lastName,
      setEmail,
      email,
   } = useStateContext();

   let values = {
      phone: phone,
   };
   const [formValues, setFormValues] = useState(values);
   const [formErrors, setFormErrors] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);
   const validate = (values) => {
      const errors = {};
      const regex_phone = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
      if (!values.phone) {
         errors.phone = 'Required';
      }
      if (!regex_phone.test(values.phone)) {
         errors.phone = 'Phone number is not valid';
      }
      return errors;
   };
   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
   };

   

   useEffect(() => {
      const fetchData = async () => {
         await axios
            .get(
               process.env.REACT_APP_API_SERVER +
                  `/api/customer/phone?phone=${formValues.phone}&store_id=${process.env.REACT_APP_STORE_ID}`
            )
            .then((res) => res.data)
            .then((data) => {
               if (data.length > 0) {
                  setFirstName(data[0].first_name);
                  setLastName(data[0].last_name);
                  setEmail(data[0].email);
                  setPhone(data[0].phone_number);
               }else{
                  setPhone(formValues.phone);
                  setFirstName('');
                  setLastName('');
                  setEmail('');
               }
               nextStep();
            })
            .catch((err) => console.log(err));
      };

      if (Object.keys(formErrors).length === 0 && isSubmit) {
         //fetch customer info
         fetchData();
      }
   }, [formErrors, isSubmit]);


   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='flex-start'
               direction='column'
            >
               <Grid item xs={12} md={6}>
                  <Typography variant='h6'>
                     Please input your phone number
                  </Typography>
               </Grid>
               <Grid item xs={12} md={6}>
                  <div>
                     <TextField
                        id='phone'
                        name='phone'
                        label='Phone'
                        value={formValues.phone}
                        color='secondary'
                        helperText={formErrors.phone}
                        onChange={handleChange}
                     />
                  </div>
               </Grid>
               <Grid
                  container
                  spacing={10}
                  direction='row'
                  justifyContent='flex-end'
                  marginTop={1}
               >
                  <Grid item xs={4}>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
};

export default CK_Phone;
